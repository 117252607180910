
import * as React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { Link, navigate } from "gatsby"
import Layout from '../../components/layout';

const SUBMIT_STATE = {
  INITIAL: '',
  PENDING: 'pending',
  SUCCESS: 'success',
  ERROR: 'error',
}

// styles
const MailActionPage = ({location}) => {

  const params = new URLSearchParams(location.search);
  const subscriberId = params.get("subscriber_id");
  const actionLabel = params.get("action_label");
  const successMsg = params.get("success_msg");

  const [submitStatus, setSubmitStatus] = React.useState(SUBMIT_STATE.INITIAL);

  const rendered = React.useRef(null);

  React.useEffect(() => {
    if(!subscriberId) {
      navigate('/');
    }
    else if(submitStatus === SUBMIT_STATE.INITIAL) {
      requestAction();
    }
  });

  async function requestAction() {
    setSubmitStatus(SUBMIT_STATE.PENDING);
    
    let response = null;
    try {
      response = await fetch('/api/subscriber/action', {
        method: 'PUT',
        body: JSON.stringify({
            SubscriberId: subscriberId,
            ActionLabel: actionLabel
        }),
        headers: {
          'Content-Type': 'application/json',
        },
      });
    }
    catch(err) {
      response = {status: 500, error: err}
    }
    // await new Promise(resolve => setTimeout(resolve, 3000));
    // response = {status: 200}

    if([200, 202].includes(response.status)) {
      setSubmitStatus(SUBMIT_STATE.SUCCESS);
    }
    else {
      setSubmitStatus(SUBMIT_STATE.ERROR);
    }
  }

  return (
    <Layout pageTitle="Home Page">
      <div className="container mx-auto text-center px-4" ref={rendered}>
        {[SUBMIT_STATE.INITIAL, SUBMIT_STATE.PENDING].includes(submitStatus) && (
          <div className="py-1 px-6 fa-2x text-gray-600">
            <FontAwesomeIcon icon={solid('circle-notch')} className="animate-spin"/>
          </div>
        )}
        {submitStatus === SUBMIT_STATE.SUCCESS && (
          <div>
            <div className="bg-green-100 border border-green-500 text-green-700 px-4 py-3 rounded-lg my-6" role="alert">
              <p className="font-bold">{successMsg}</p>
            </div>
            <Link to="/" className="bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">Home Page</Link>
          </div>
        )}
        {submitStatus === SUBMIT_STATE.ERROR && (
          <div>
            <div className="bg-red-100 border border-red-500 text-red-700 px-4 py-3 mt-2 rounded-lg my-6" role="alert">
              <p className="font-bold">Something went wrong. Please try the link from your email again.</p>
              <p className="small">If you keep getting this message, please <Link to="/contact/" className="text-blue-600">contact me</Link> or
              send an email to <a className="text-blue-600" href="mailto:webmaster@ryansivek.com">webmaster@ryansivek.com</a></p>
            </div>
            <Link to="/" className="bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">Home Page</Link>
          </div>
        )}
      </div>
    </Layout>
  )
};

export default MailActionPage;
